$font: Futura, "Trebuchet MS", Arial, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
sans-serif;

$color: #FFF;

//Bubble
$bubbleSize: 1000px;

h1 {
  font-family: $font;
  font-weight: bolder;
  color: $color;
}

h2 {
  font-family: $font;
  font-weight: 501;
  color: $color;
}

.navbar-style {
  background-color: transparent;
  font-weight: 700;
  color: $color;
  position: fixed;
}

.navbar-position {
  background-image: linear-gradient(134deg, #222222 0%, #000000 100%);
  width: 100%;
  height: 60px;
  position: fixed;
}

.project-descrition {
    margin-top: 40px;
}

.project-card {
    margin-bottom: 50px;
}

h2 {
  font-family: $font;
  font-weight: lighter;
  color: $color;
}

h4 {
  font-family: $font;
  color: $color;
}

#bubbleContainer {
  overflow: hidden;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.bubble{
  width: $bubbleSize;
  height: $bubbleSize;
  position: relative;
  background: #FFF;
  right: -700px;
  top: -150px;
  border-radius: ($bubbleSize / 2);
  background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%);
}

.followButton {
    background-color: transparent;
    border: none;
    color: white;
    font-family: $font;
    font-weight: lighter;
}

.followIcon {
    width: 41px;
    height: 41px;
}

.followIconCol {
    float: left;
}

.followTitle {
    margin-top: 10px;
    margin-left: 8px;
    float: left;
}
