body {
  margin: 0;
  background: #222222;
  background-image: linear-gradient(134deg, #222222 0%, #000000 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #FFF;

  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
